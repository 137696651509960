import React from 'react';
import Layout from '../layout/layout';

import './calendar.scss';

const Calendar = () => {
  return (
    <Layout>
      <div className="calendar">
        <h1>My weekly puzzle calendar</h1>

        <h3>Every day</h3>
        <p>
          Note: More user-friendly .puz file links are available on{' '}
          <a href="https://crosswordfiend.com/download">Crossword Fiend (CF)</a>{' '}
          and <a href="https://www.cruciverb.com">Cruciverb.com (CV)</a> for all
          of these dailies.
        </p>
        <ul>
          <li>
            <a href="https://games.latimes.com/games/daily-crossword/">
              Los Angeles Times
            </a>
          </li>
          <li>
            <a href="https://www.nytimes.com/crosswords">New York Times</a>{' '}
            (subscription required)
          </li>
          <li>
            <a href="https://www.uexpress.com/puzzle-society?amu=/iwin-crossword">
              Universal Daily Crossword
            </a>{' '}
            (additional 21x21 Sunday puzzle below)
          </li>
          <li>
            <a href="https://blogs.wsj.com/puzzle/">Wall Street Journal</a> (no
            Sunday puzzle)
          </li>
        </ul>
        <h3>Monday</h3>
        <ul>
          <li>
            <a href="https://www.newyorker.com/crossword/puzzles-dept">
              The New Yorker
            </a>{' '}
            (web only)
          </li>
          <li>
            <a href="http://nymag.com/crossword/">New York Magazine</a> (Matt
            Gaffney, web only, new ones every other week)
          </li>
          <li>
            <a href="https://www.brendanemmettquigley.com">
              Brendan Emmett Quigley
            </a>
          </li>
        </ul>
        <h3>Tuesday</h3>
        <ul>
          <li>
            <a href="https://www.crosswordnation.com">Crossword Nation</a>{' '}
            (Elizabeth C. Gorski, subscription required)
          </li>
        </ul>
        <h3>Wednesday</h3>
        <ul>
          <li>
            <a href="https://avxwords.com/#/">American Values Crossword Club</a>{' '}
            (subscription required)
          </li>
        </ul>
        <h3>Thursday</h3>
        <ul>
          <li>
            <a href="https://crosswordfiend.com/download/">Jonesin'</a> (Matt
            Jones, <a href="https://crosswordfiend.com/download">CF</a> or{' '}
            <a href="https://www.cruciverb.com">CV</a>)
          </li>
          <li>
            <a href="https://www.brendanemmettquigley.com">
              Brendan Emmett Quigley
            </a>
          </li>
        </ul>
        <h3>Friday</h3>
        <ul>
          <li>
            <a href="https://www.chronicle.com/subscribe">
              Chronicle of Higher Education
            </a>{' '}
            (every other week during the summer,{' '}
            <a href="https://crosswordfiend.com/download">CF</a> or{' '}
            <a href="https://www.cruciverb.com">CV</a>)
          </li>
          <li>
            <a href="https://www.mgwcc.com">
              Matt Gaffney's Weekly Crossword Contest
            </a>{' '}
            (subscription required)
          </li>
          <li>
            <a href="https://inkubatorcrosswords.com">The Inkubator</a>{' '}
            (subscription required, every other week)
          </li>
          <li>
            <a href="https://www.newyorker.com/crossword/puzzles-dept">
              The New Yorker
            </a>{' '}
            (web only)
          </li>
          <li>
            <a href="https://webflow-production.spyscape.com/crosswords">
              Skyscape
            </a>{' '}
            (web only)
          </li>
        </ul>
        <h3>Saturday</h3>
        <p>
          Might look at my indie list below or catch up on anything I missed,
          otherwise nothin'.
        </p>
        <h3>Sunday</h3>
        <ul>
          <li>
            <a href="https://crosswordfiend.com/download/">
              Universal Sunday Crossword (CF)
            </a>
          </li>
          <li>
            <a href="https://www.washingtonpost.com/crossword-puzzles/sunday-evan-birnholz/">
              Washington Post
            </a>{' '}
            (Evan Birnholz, .puz version on{' '}
            <a href="https://crosswordfiend.com/download">CF</a>)
          </li>
        </ul>
        <h3>Indie puzzles</h3>
        <p>
          These are the folks I follow and check up on when I'm in the mood for
          a puzzle and have done all the publications already. They all publish
          semi-regularly and are consistently enjoyable. If you have anyone you
          suggest adding to my list, please pass them along. I would really
          enjoy more diverse representation, in particular.
        </p>
        <ul>
          <li>
            <a href="http://arctanxwords.blogspot.com/p/puzzles.html">
              Christopher Adams
            </a>
          </li>
          <li>
            <a href="https://gluttonforpun.blogspot.com">Erik Agard</a>
          </li>
          <li>
            <a href="https://chriswords.com">Chris King</a>
          </li>
          <li>
            <a href="http://blog.bewilderinglypuzzles.com">Will Nediger</a> (I
            also really enjoy that he publishes a post at the beginning of each
            month about indie puzzles I might have missed otherwise)
          </li>
          <li>
            <a href="http://gridsthesedays.blogspot.com">Paolo Pasco</a>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default Calendar;
